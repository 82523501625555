/* eslint-disable camelcase */

export const stripTags = (htmlString) => {
    return htmlString.replace(/(<([^>]+)>)/gi, '')
}

export const convertDatoCmsImgPathsToLocal = (str) => {
    if (!str) return str
    const replacement = '/datoCmsDownload'
    return str.replace(/https:\/\/www\.datocms-assets\.com/g, replacement)
}

export const htmlDecode = (input) => {
    if (typeof window !== 'undefined') {
        const doc = new DOMParser().parseFromString(input, 'text/html')
        return doc.documentElement.textContent
    }
    return input
}

export const createMarkup = (htmlString) => {
    return {
        __html: htmlString,
    }
}
