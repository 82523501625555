export const chromeExtensionLink =
    'https://chrome.google.com/webstore/detail/startpage-%E2%80%94-private-searc/fgmjlmbojbkmdpofahffgcpkhkngfpef?hl=en'

export const getChromeExtensionLink = (languageCode = 'en') => {
    return `https://chrome.google.com/webstore/detail/startpage-%E2%80%94-private-searc/fgmjlmbojbkmdpofahffgcpkhkngfpef?hl=${languageCode}`
}

export const firefoxExtensionLink =
    'https://addons.mozilla.org/addon/startpage-private-search/?src=external-marketing-pages'

export const edgeExtensionLink =
    'https://microsoftedge.microsoft.com/addons/detail/startpage-%E2%80%94-private-searc/jogphcaagccljpbnoddeknjjngefidmm'

export const firefoxExtensionStoreLink = 'https://addons.mozilla.org/en-US/firefox/addon/startpage-private-search'

export const chromeNewTabStoreLink =
    'https://chrome.google.com/webstore/detail/startpage-%E2%80%94-private-searc/nfepmeogoknijeenahgdfobjfemdahmo'

export const firefoxNewTabStoreLink =
    'https://addons.mozilla.org/en-US/firefox/addon/startpage-private-search-tab/?src=external-marketing-pages'

export const edgeNewTabStoreLink =
    'https://microsoftedge.microsoft.com/addons/detail/startpage-%E2%80%94-private-searc/mokiigmgbcannedomnjkiceljncglibo'

export const chromeProtectionExtensionLink = (languageCode = 'en') =>
    `https://chrome.google.com/webstore/detail/startpage-%E2%80%94-privacy-prote/cdkhedhmncjnpabolpjceohohlefegak?hl=${languageCode}`

export const firefoxProtectionInlineInstallLink =
    'https://addons.mozilla.org/firefox/downloads/latest/startpage-privacy-protection'

export const firefoxClassicInlineInstallLink =
    'https://addons.mozilla.org/firefox/downloads/latest/startpage-private-search'

export const firefoxProtectionExtensionLink = (languageCode = 'en') => {
    const ffLangCode = languageCode === 'de' ? 'de' : 'en-US'
    return `https://addons.mozilla.org/${ffLangCode}/firefox/addon/startpage-privacy-protection/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search`
}
