import React from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/react'
import {convertDatoCmsImgPathsToLocal} from '../../../util/datoCmsDataFactory'
import {colors, socialIcons} from '../../../constants'

import StartpageLogoRedesign from '../../../images/startpage-logo-redesign.svg'

const CONTAINER_STYLES = `
    width: 1200px;
    margin: auto;
    h2 {
        font-size: 48px;
        font-weight: 600;
    }
`

const RESPONSIVE_CONTAINER_STYLES = `
    width: 100%;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`

const Footer = ({languageCode, links, footerBlogAsset, additionalLinks, responsive = false, overrideStyles}) => {
    const homeLink = `https://www.startpage.com/${languageCode}`
    const [privacyPolicyLink, aboutUsLink, blogLink, pressLink, startmailLink] = links
    const footerLinkList = [privacyPolicyLink, aboutUsLink, pressLink, startmailLink]
    if (additionalLinks) {
        footerLinkList.push(...additionalLinks)
    }
    if (languageCode === 'de') {
        footerLinkList.splice(1, 0, {
            text: 'Impressum',
            url: 'https://support.startpage.com/hc/de/articles/4521527855636-Impressum',
        })
    }
    return (
        <section
            className="footer"
            css={css`
                background: ${colors.whisper};
                margin-top: 5px;
                padding: 3rem 2rem;
                ${overrideStyles}
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    ${CONTAINER_STYLES}
                    ${responsive && RESPONSIVE_CONTAINER_STYLES}
                `}
            >
                <div
                    className="footer-widget"
                    css={css`
                        ${responsive &&
                        `@media (max-width: 1100px) {
                            display: none;
                        }`}
                    `}
                >
                    <a href={blogLink.url} target="_blank" rel="noopener noreferrer">
                        <img src={convertDatoCmsImgPathsToLocal(footerBlogAsset.url)} alt="blog link" />
                    </a>
                </div>
                <div
                    className="footer-links"
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        svg {
                            width: 104px;
                        }
                        .links {
                            margin-top: 1.5rem;
                        }
                    `}
                >
                    <a href={homeLink} rel="noopener noreferrer">
                        <StartpageLogoRedesign />
                    </a>
                    <div
                        className="links"
                        css={css`
                            ${responsive ? '@media(max-width: 1100px) { margin-bottom: 2rem; }' : ''}
                        `}
                    >
                        {footerLinkList.map(({text, url}, i) => {
                            return (
                                <a
                                    css={css`
                                        text-decoration: none;
                                        margin: 0.5rem;
                                        font-size: 1rem;
                                        &:hover {
                                            color: ${colors.brandBlue};
                                            cursor: pointer;
                                        }
                                    `}
                                    key={i}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={text}
                                >
                                    {text}
                                </a>
                            )
                        })}
                    </div>
                </div>
                <div
                    className="footer-social"
                    css={css`
                        svg {
                            width: 40px;
                            height: 40px;
                            margin-right: 0.5rem;
                        }
                    `}
                >
                    {socialIcons.map((socialItem, i) => {
                        const {url, icon, label} = socialItem
                        return (
                            <a href={url} target="_blank" rel="noopener noreferrer" aria-label={label} key={i}>
                                {icon()}
                            </a>
                        )
                    })}
                </div>
                {responsive && (
                    <div
                        className="footer-widget"
                        css={css`
                            display: none;
                            margin-top: 2rem;
                            @media (max-width: 1100px) {
                                display: block;
                            }
                        `}
                    >
                        <a href={blogLink.url} target="_blank" rel="noopener noreferrer">
                            <img src={convertDatoCmsImgPathsToLocal(footerBlogAsset.url)} alt="blog link" />
                        </a>
                    </div>
                )}
            </div>
        </section>
    )
}

Footer.propTypes = {
    languageCode: PropTypes.string,
    links: PropTypes.array,
}

Footer.defaultProps = {
    languageCode: `en`,
    links: [
        {
            text: `Privacy policy`,
            url: `https://www.startpage.com/en/search/privacy-policy.html`,
        },
        {text: `About us`, url: `https://www.startpage.com/en/about-us.html`},
        {text: `Blog`, url: `https://www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://www.startpage.com/privacy-please/press/`},
        {text: `StartMail`, url: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
}

export default Footer
